#join-form div:nth-child(1) {
  width: 80%;
}
#menu-name {
  border-bottom: 1px solid #fff !important;
  opacity: 0.8;
}
#menu-name::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
  opacity: 0.9;
}
#menu-name::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  opacity: 0.8;
}
#menu-name:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  opacity: 0.8;
}
#menu-name:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
  opacity: 0.8;
}
