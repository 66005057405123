#landscape{
  position: absolute;
  top: 0px;
  left: 0px;
  background: #000000;
  width: 100%;
  height: 100%;
  display: block; 
  z-index: 20000;
  opacity: 0.8;
  margin:0 auto;
}
#landscape div{
 color: #FFFFFF;                                  
 opacity: 1;
 top: 50%;
 position: absolute;
 text-align: center;
 display: inline-block;
 width: 100%;
}